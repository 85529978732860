import { tv } from 'tailwind-variants';

const styles = tv({
  base: 'inline-block',
  variants: {
    axis: {
      horizontal: `
        w-[var(--spacer-size-initial)]
        min-w-[var(--spacer-size-initial)]

        sm:w-[var(--spacer-size-sm)]
        sm:min-w-[var(--spacer-size-sm)]

        md:w-[var(--spacer-size-md)]
        md:min-w-[var(--spacer-size-md)]

        lg:w-[var(--spacer-size-lg)]
        lg:min-w-[var(--spacer-size-lg)]

        xl:w-[var(--spacer-size-xl)]
        xl:min-w-[var(--spacer-size-xl)]

        2xl:w-[var(--spacer-size-2xl)]
        2xl:min-w-[var(--spacer-size-2xl)]
      `,
      vertical: `
        h-[var(--spacer-size-initial)]
        min-h-[var(--spacer-size-initial)]

        sm:h-[var(--spacer-size-sm)]
        sm:min-h-[var(--spacer-size-sm)]

        md:h-[var(--spacer-size-md)]
        md:min-h-[var(--spacer-size-md)]

        lg:h-[var(--spacer-size-lg)]
        lg:min-h-[var(--spacer-size-lg)]

        xl:h-[var(--spacer-size-xl)]
        xl:min-h-[var(--spacer-size-xl)]

        2xl:h-[var(--spacer-size-2xl)]
        2xl:min-h-[var(--spacer-size-2xl)]
      `,
    },
  },
});

export default styles;
