import React from 'react';
import type { HTMLAttributes, ElementRef, Ref, ReactNode } from 'react';

import { Slot } from '@radix-ui/react-slot';

import { createVar } from '@backyard-ui/styles';
import type { StyleProps, Token } from '@backyard-ui/styles';

import { filterFalsyValue } from '@backyard-ui/utils';

import styles from './Text.styles';

export interface TextProps
  extends StyleProps<HTMLAttributes<HTMLParagraphElement>> {
  /**
   * The CSS `text-align` property
   */
  align?: 'left' | 'center' | 'right' | 'justify' | 'start' | 'end';

  /**
   * The CSS `color` property
   *
   * @default black
   */
  color?: Token<'colors'>;

  /**
   * The CSS `text-decoration-line` property
   */
  decoration?: 'underline' | 'overline' | 'line-through' | 'none';

  /**
   * The CSS `line-clamp` property
   */
  noOfLines?: number;

  /**
   * The Text size
   *
   * @default lg
   */
  size?:
    | 'xs'
    | 'sm'
    | 'md'
    | 'lg'
    | 'xl'
    | '2xl'
    | '3xl'
    | '4xl'
    | '5xl'
    | '6xl';

  /**
   * The CSS `text-transform` property
   */
  transform?: 'uppercase' | 'lowercase' | 'capitalize' | 'none';

  /**
   * The CSS `font-weight` property
   *
   * @default normal
   */
  weight?: Token<'fontWeights'>;

  /**
   * Change the component to the HTML tag or custom component of the only child
   */
  asChild?: boolean;

  /**
   * The CSS `font-style` property
   *
   * @default false
   */
  isItalic?: boolean;

  /**
   * The ref to the HTML DOM element
   */
  ref?: Ref<HTMLHeadingElement>;

  /**
   * The text children
   */
  children: ReactNode;
}

const Text = React.forwardRef<ElementRef<'p'>, TextProps>((props, ref) => {
  const {
    align,
    color = 'black',
    decoration,
    noOfLines,
    size = 'lg',
    transform,
    weight = 'normal',
    UNSAFE_className,
    UNSAFE_style,
    asChild = false,
    isItalic = false,
    ...rest
  } = props;

  const Component = asChild ? Slot : 'p';

  const dynamicStyles = filterFalsyValue({
    '--text-color': createVar(`colors-${color}`),
    '--text-clamp': noOfLines,
    '--text-weight': weight,
  });

  return (
    <Component
      className={styles({
        align,
        size,
        transform,
        clamp: Boolean(noOfLines),
        decoration,
        isItalic,
        className: UNSAFE_className,
      })}
      style={{ ...dynamicStyles, ...UNSAFE_style }}
      ref={ref}
      {...rest}
    />
  );
});

Text.displayName = '@backyard-ui/core/Text';

export default Text;
