import React from 'react';
import type { ButtonHTMLAttributes, Ref, ReactNode, ElementRef } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { Slot, Slottable } from '@radix-ui/react-slot';
import { Spinner } from '../Spinner';
import { Icon } from '../Icon';

import styles from './Button.styles';

export interface ButtonProps
  extends StyleProps<ButtonHTMLAttributes<HTMLButtonElement>> {
  /**
   * The Button appearance
   *
   * @default primary
   */
  appearance?:
    | 'critical'
    | 'info'
    | 'neutral'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'tertiary'
    | 'warning';

  /**
   * Used to apply social login styles
   */
  social?: 'apple' | 'facebook' | 'google' | 'twitter';

  /**
   * The `border-radius` property
   *
   * @default xl
   */
  radius?: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | 'full';

  /**
   * The Button size
   *
   * @default sm
   */
  size?: 'xs' | 'sm' | 'md';

  /**
   * The variant is used
   * to change the visual communication.
   *
   * @default solid
   */
  variant?: 'outline' | 'ghost' | 'solid' | 'subtle';

  /**
   * Display an icon.
   */
  iconLeft?: ReactNode;

  /**
   * Display an icon.
   */
  iconRight?: ReactNode;

  /**
   * Set disabled state.
   */
  isDisabled?: boolean;

  /**
   * Set loading state.
   */
  isLoading?: boolean;

  /**
   * Set button full width.
   */
  isFullWidth?: boolean;

  /**
   * Change the component to the HTML tag or custom component of the only child
   */
  asChild?: boolean;

  /**
   * The ref to the HTML DOM element
   */
  ref?: Ref<HTMLButtonElement>;

  /**
   *
   */
  children: ReactNode;
}

const Button = React.forwardRef<ElementRef<'button'>, ButtonProps>(
  (props, ref) => {
    const {
      appearance = 'primary',
      radius = 'xl',
      size = 'sm',
      social,
      variant = 'solid',
      iconLeft,
      iconRight,
      asChild = false,
      isDisabled = false,
      isFullWidth = false,
      isLoading = false,
      children,
      UNSAFE_className,
      UNSAFE_style,
      ...rest
    } = props;

    const Component = asChild ? Slot : 'button';

    return (
      <Component
        className={styles({
          appearance: social ? undefined : appearance,
          radius,
          size,
          social,
          variant,
          isFullWidth,
          className: UNSAFE_className,
        })}
        style={UNSAFE_style}
        disabled={isDisabled || isLoading}
        ref={ref}
        {...rest}
      >
        {iconLeft && !isLoading && (
          <Icon
            children={iconLeft}
            size={size}
            data-testid="Button-icon--left"
          />
        )}

        {isLoading && <Spinner size={size} />}

        <Slottable>{children}</Slottable>

        {iconRight && !isLoading && (
          <Icon
            children={iconRight}
            size={size}
            data-testid="Button-icon--right"
          />
        )}
      </Component>
    );
  }
);

Button.displayName = '@backyard-ui/core/Button';

export default Button;
