import React from 'react';
import type { HTMLAttributes, ElementRef, Ref, ReactNode } from 'react';

import styles from './VisuallyHidden.styles';

export interface VisuallyHiddenProps extends HTMLAttributes<HTMLDivElement> {
  /**
   * The ref to the HTML DOM element
   */
  ref?: Ref<HTMLSpanElement>;

  /**
   * The children elements
   */
  children: ReactNode;
}

const VisuallyHidden = React.forwardRef<
  ElementRef<'span'>,
  VisuallyHiddenProps
>((props, ref) => {
  const { className, ...rest } = props;

  return <span className={styles({ className })} ref={ref} {...rest} />;
});

VisuallyHidden.displayName = '@backyard-ui/core/VisuallyHidden';

export default VisuallyHidden;
