import { tv } from 'tailwind-variants';

const styles = tv(
  {
    base: `
      relative
      mx-auto
    `,
    variants: {
      size: {
        xs: 'max-w-xs',
        sm: 'max-w-sm',
        md: 'max-w-md',
        lg: 'max-w-lg',
        xl: 'max-w-xl',
        '2xl': 'max-w-2xl',
        '3xl': 'max-w-3xl',
        '4xl': 'max-w-4xl',
        '5xl': 'max-w-5xl',
        '6xl': 'max-w-6xl',
        '7xl': 'max-w-7xl',
        full: 'max-w-full',
      },
      padding: {
        xs: 'px-0.5',
        sm: 'px-1',
        md: 'px-1.5',
        lg: 'px-2',
        xl: 'px-2.5',
      },
    },
  },
  {
    responsiveVariants: true,
  }
);

export default styles;
