import { tv } from 'tailwind-variants';

const styles = tv({
  base: `
    animate-spin
    [&_circle]:animate-spinner
  `,
  variants: {
    appearance: {
      critical: '[&_circle]:stroke-critical-600',
      info: '[&_circle]:stroke-info-600',
      primary: '[&_circle]:stroke-primary-600',
      secondary: '[&_circle]:stroke-secondary-600',
      success: '[&_circle]:stroke-success-600',
      tertiary: '[&_circle]:stroke-tertiary-600',
      warning: '[&_circle]:stroke-warning-600',
    },
    color: {
      black: '[&_circle]:stroke-black',
      current: '[&_circle]:stroke-current',
      white: '[&_circle]:stroke-white',
    },
    size: {
      xs: `
        h-3
        w-3
      `,
      sm: `
        h-4
        w-4
      `,
      md: `
        h-5
        w-5
      `,
      lg: `
        h-6
        w-6
      `,
      xl: `
        h-7
        w-7
      `,
    },
  },
});

export default styles;
