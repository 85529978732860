import { tv } from 'tailwind-variants';

const styles = tv({
  base: 'text-[var(--text-color)] font-[var(--text-weight)]',
  variants: {
    align: {
      left: 'text-left',
      center: 'text-center',
      right: 'text-right',
      justify: 'text-justify',
      start: 'text-start',
      end: 'text-end',
    },
    size: {
      xs: 'text-xs',
      sm: 'text-sm',
      md: 'text-md',
      lg: 'text-lg',
      xl: `
        text-lg
        md:text-xl
      `,
      '2xl': `
        text-xl
        md:text-2xl
      `,
      '3xl': `
        text-2xl
        md:text-3xl
      `,
      '4xl': `
        text-2xl
        md:text-4xl
      `,
      '5xl': `
        text-2xl
        md:text-5xl
      `,
      '6xl': `
        text-2xl
        md:text-6xl
      `,
    },
    transform: {
      uppercase: 'uppercase',
      lowercase: 'lowercase',
      capitalize: 'capitalize',
      none: 'normal-case',
    },
    clamp: {
      true: 'line-clamp-[var(--text-clamp)]',
      false: 'block',
    },
    decoration: {
      underline: 'underline',
      overline: 'overline',
      'line-through': 'line-through',
      none: 'no-underline',
    },
    isItalic: {
      true: 'italic',
    },
  },
});

export default styles;
