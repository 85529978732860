import React from 'react';
import type { HTMLAttributes, ElementRef, Ref, ReactNode } from 'react';

import { VariantProps } from 'tailwind-variants';

import { useGridContext } from '../Grid/Grid.context';

import styles from './Container.styles';

export interface ContainerProps extends HTMLAttributes<HTMLDivElement> {
  /**
   * The container size
   *
   * @default lg
   */
  size?: VariantProps<typeof styles>['size'];

  /**
   * The ref to the HTML DOM element
   */
  ref?: Ref<HTMLDivElement>;

  /**
   * The children elements
   */
  children: ReactNode;
}

const Container = React.forwardRef<ElementRef<'div'>, ContainerProps>(
  (props, ref) => {
    const { size = 'md', className, ...rest } = props;
    const grid = useGridContext();

    return (
      <div
        className={styles({ padding: grid?.gutter || 'lg', size, className })}
        ref={ref}
        {...rest}
      />
    );
  }
);

Container.displayName = '@backyard-ui/core/Container';

export default Container;
