import { tv } from 'tailwind-variants';

const styles = tv({
  base: 'flex',
  variants: {
    align: {
      true: `
        f-items-[var(--f-items-initial)]
        sm:f-items-[var(--f-items-sm)]
        md:f-items-[var(--f-items-md)]
        lg:f-items-[var(--f-items-lg)]
        xl:f-items-[var(--f-items-xl)]
        2xl:f-items-[var(--f-items-2xl)]
      `,
    },
    basis: {
      true: 'basis-[var(--f-basis)]',
    },
    direction: {
      true: `
        f-direction-[var(--f-direction-initial)]
        sm:f-direction-[var(--f-direction-sm)]
        md:f-direction-[var(--f-direction-md)]
        lg:f-direction-[var(--f-direction-lg)]
        xl:f-direction-[var(--f-direction-xl)]
        2xl:f-direction-[var(--f-direction-2xl)]
      `,
    },
    flex: {
      true: 'flex-[var(--f-flex)]',
    },
    grow: {
      true: 'grow-[var(--f-grow)]',
    },
    justify: {
      true: `
        f-justify-[var(--f-justify-initial)]
        sm:f-justify-[var(--f-justify-sm)]
        md:f-justify-[var(--f-justify-md)]
        lg:f-justify-[var(--f-justify-lg)]
        xl:f-justify-[var(--f-justify-xl)]
        2xl:f-justify-[var(--f-justify-2xl)]
      `,
    },
    shrink: {
      true: 'shrink-[var(--f-shrink)]',
    },
    wrap: {
      wrap: 'flex-wrap',
      'wrap-reverse': 'flex-wrap-reverse',
      nowrap: 'flex-nowrap',
    },
  },
});

export default styles;
