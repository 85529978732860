import React from 'react';
import type { ElementRef, Ref, CSSProperties } from 'react';

import { genResponsiveVars } from '@backyard-ui/styles';
import type { ResponsiveValue } from '@backyard-ui/styles';

import { filterFalsyValue } from '@backyard-ui/utils';

import { Slot } from '@radix-ui/react-slot';
import type { SlotProps } from '@radix-ui/react-slot';

import styles from './Flex.styles';

export interface FlexProps extends SlotProps {
  /**
   * The CSS `align-items` property
   */
  align?: ResponsiveValue<
    'center' | 'flex-start' | 'flex-end' | 'baseline' | 'stretch'
  >;

  /**
   * The CSS `flex-basis` property
   */
  basis?: CSSProperties['flexBasis'];

  /**
   * The CSS `flex-direction` property
   *
   * @default row
   */
  direction?: ResponsiveValue<
    'row' | 'row-reverse' | 'column' | 'column-reverse'
  >;

  /**
   * The CSS `flex` property
   */
  flex?: CSSProperties['flex'];

  /**
   * The CSS `flex-grow` property
   */
  grow?: CSSProperties['flexGrow'];

  /**
   * The CSS `justify-content` property
   */
  justify?: ResponsiveValue<
    | 'normal'
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'baseline'
    | 'stretch'
  >;

  /**
   * The CSS `flex-shrink` property
   */
  shrink?: CSSProperties['flexShrink'];

  /**
   * The CSS `flex-wrap` property
   */
  wrap?: 'wrap' | 'wrap-reverse' | 'nowrap';

  /**
   * Change the component to the HTML tag or custom component of the only child
   */
  asChild?: boolean;

  /**
   * The ref to the HTML DOM element
   */
  ref?: Ref<HTMLDivElement>;
}

const Flex = React.forwardRef<ElementRef<'div'>, FlexProps>((props, ref) => {
  const {
    align,
    basis,
    direction,
    flex,
    grow,
    justify,
    shrink,
    wrap,
    className,
    style,
    asChild = false,
    ...rest
  } = props;

  const Component = asChild ? Slot : 'div';

  const dynamicStyles = filterFalsyValue({
    '--f-basis': basis,
    '--f-flex': flex,
    '--f-grow': grow,
    '--f-shrink': shrink,
    ...genResponsiveVars(align, 'f-items', (value) => value),
    ...genResponsiveVars(direction, 'f-direction', (value) => value),
    ...genResponsiveVars(justify, 'f-justify', (value) => value),
  });

  return (
    <Component
      className={styles({
        align: Boolean(align),
        basis: Boolean(basis),
        direction: Boolean(direction),
        grow: Boolean(grow),
        wrap,
        justify: Boolean(justify),
        className,
      })}
      style={{
        ...dynamicStyles,
        ...style,
      }}
      ref={ref}
      {...rest}
    />
  );
});

Flex.displayName = '@backyard-ui/core/Flex';

export default Flex;
