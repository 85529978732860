import { createContext } from '@backyard-ui/utils';
import type { GridProps } from './Grid';

export interface GridContext
  extends Pick<GridProps, 'columns' | 'gutter' | 'isGrow' | 'noGutters'> {}

const { Provider: GridProvider, useContext: useGridContext } =
  createContext<GridContext>({
    name: '@backyard-ui/core/GridContext',
    strict: false,
  });

export { GridProvider, useGridContext };
