import React from 'react';
import type { HTMLAttributes, ElementRef, Ref } from 'react';

import { createVar, genResponsiveVars } from '@backyard-ui/styles';
import type { ResponsiveValue, Token } from '@backyard-ui/styles';

import styles from './Spacer.styles';

export interface SpacerProps extends HTMLAttributes<HTMLSpanElement> {
  /**
   * The CSS `width` or `height` property
   *
   * @default horizontal
   */
  axis?: 'horizontal' | 'vertical';

  /**
   * The Spacer size
   *
   * @default 1
   */
  size?: ResponsiveValue<Token<'spacing'>>;

  /**
   * The ref to the HTML DOM element
   */
  ref?: Ref<HTMLDivElement>;
}

const Spacer = React.forwardRef<ElementRef<'span'>, SpacerProps>(
  (props, ref) => {
    const {
      axis = 'horizontal',
      size = '1',
      className,
      style,
      ...rest
    } = props;

    const dynamicStyles = genResponsiveVars(size, 'spacer-size', (value) =>
      createVar(
        `spacing-${
          String(value).includes('.')
            ? String(value).replace('.', '\\.')
            : value
        }`
      )
    );

    return (
      <span
        className={styles({ axis, className })}
        style={{ ...dynamicStyles, ...style }}
        ref={ref}
        {...rest}
      />
    );
  }
);

Spacer.displayName = '@backyard-ui/core/Spacer';

export default Spacer;
