import React from 'react';
import type { SVGAttributes, ElementRef } from 'react';

import type { StyleProps } from '@backyard-ui/styles';
import type { VariantProps } from 'tailwind-variants';

import { Slot } from '@radix-ui/react-slot';
import { VisuallyHidden } from '../VisuallyHidden';

import styles from './Icon.styles';

export interface IconProps extends StyleProps<SVGAttributes<HTMLOrSVGElement>> {
  /**
   * The Icon appearance
   */
  appearance?:
    | 'critical'
    | 'info'
    | 'primary'
    | 'success'
    | 'secondary'
    | 'tertiary'
    | 'warning';

  /**
   * The Icon color
   *
   * @default current
   */
  color?: 'black' | 'current' | 'white';

  /**
   * The Icon size
   *
   * @default md
   */
  size?: VariantProps<typeof styles>['size'];

  /**
   * The acessible label
   */
  label?: string;

  /**
   * The html role of the icon.
   *
   * @default presentation
   */
  role?: 'presentation' | 'img';
}

const Icon = React.forwardRef<ElementRef<'div'>, IconProps>((props, ref) => {
  const {
    appearance,
    color = 'current',
    label,
    role = 'presentation',
    size = 'md',
    UNSAFE_className,
    UNSAFE_style,
    children,
    ...rest
  } = props;

  const child = React.isValidElement(children)
    ? React.Children.only(children)
    : null;

  return (
    <div className={styles().root()}>
      <Slot
        className={styles({
          appearance,
          color: appearance ? undefined : color,
          size,
        }).base({
          className: UNSAFE_className,
        })}
        style={UNSAFE_style}
        ref={ref}
        {...rest}
      >
        {React.cloneElement(child as React.ReactElement, {
          'aria-hidden': 'true',
          focusable: 'false',
          role,
        })}
      </Slot>

      {label && (
        <VisuallyHidden data-testid="Icon-label">{label}</VisuallyHidden>
      )}
    </div>
  );
});

Icon.displayName = '@backyard-ui/core/Icon';

export default Icon;
