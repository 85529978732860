import { tv } from 'tailwind-variants';

const styles = tv({
  base: `
    inline-flex
    select-none

    appearance-none
    items-center

    gap-1.5
    justify-center
    space-x-2

    border-2
    border-transparent

    font-semibold
    no-underline

    outline-none

    transition
    duration-100
    ease-in

    focus:ring-2

    disabled:cursor-not-allowed
    disabled:border-neutral-100
    disabled:bg-neutral-100
    disabled:text-neutral-300
    disabled:shadow-none
    disabled:hover:bg-neutral-100
  `,
  variants: {
    appearance: {
      critical: '',
      info: '',
      neutral: '',
      primary: '',
      secondary: '',
      success: '',
      tertiary: '',
      warning: '',
    },
    size: {
      xs: `
        h-7
        px-3

        text-sm
      `,
      sm: `
        h-10
        px-4

        text-lg
      `,
      md: `
        h-12
        px-6

        text-xl
      `,
    },
    social: {
      apple: '',
      facebook: '',
      google: '',
      twitter: '',
    },
    radius: {
      sm: 'rounded-sm',
      md: 'rounded',
      lg: 'rounded-md',
      xl: 'rounded-lg',
      '2xl': 'rounded-xl',
      full: 'rounded-full',
    },
    variant: {
      ghost: '',
      outline: 'border-current',
      solid: '',
      subtle: `
        bg-white
        shadow-sm
        active:shadow-none
      `,
    },
    isFullWidth: {
      true: 'w-full',
    },
  },
  compoundVariants: [
    //outline
    {
      appearance: 'critical',
      variant: 'outline',
      className: `
        text-critical-600

        hover:bg-critical-100
        focus:ring-critical-300
        active:bg-critical-200
      `,
    },
    {
      appearance: 'info',
      variant: 'outline',
      className: `
        text-info-700

        hover:bg-info-100
        focus:ring-info-300
        active:bg-info-200
      `,
    },
    {
      appearance: 'neutral',
      variant: 'outline',
      className: `
        text-neutral-800

        hover:bg-neutral-100
        focus:ring-neutral-300
        active:bg-neutral-200
      `,
    },
    {
      appearance: 'primary',
      variant: 'outline',
      className: `
        text-primary-700

        hover:bg-primary-100
        focus:ring-primary-300
        active:bg-primary-200
      `,
    },
    {
      appearance: 'secondary',
      variant: 'outline',
      className: `
        text-secondary-600

        hover:bg-secondary-100
        focus:ring-secondary-300
        active:bg-secondary-200
      `,
    },
    {
      appearance: 'success',
      variant: 'outline',
      className: `
        text-primary-700

        hover:bg-primary-100
        focus:ring-primary-300
        active:bg-primary-200
      `,
    },
    {
      appearance: 'tertiary',
      variant: 'outline',
      className: `
        text-tertiary-600

        hover:bg-tertiary-100
        focus:ring-tertiary-300
        active:bg-tertiary-200
      `,
    },
    {
      appearance: 'warning',
      variant: 'outline',
      className: `
        text-warning-700

        hover:bg-warning-100
        hover:text-neutral-800
        focus:ring-warning-300
        active:bg-warning-200
        active:text-neutral-800
      `,
    },
    {
      social: 'apple',
      variant: 'outline',
      className: `
        text-black

        hover:bg-black/5
        focus:ring-black/30
        active:bg-black/20
      `,
    },
    {
      social: 'facebook',
      variant: 'outline',
      className: `
        text-product-facebook

        hover:bg-product-facebook/5
        focus:ring-product-facebook/30
        active:bg-product-facebook/25
      `,
    },
    {
      social: 'twitter',
      variant: 'outline',
      className: `
        text-product-twitter

        hover:bg-product-twitter/5
        focus:ring-product-twitter/30
        active:bg-product-twitter/25
      `,
    },
    // solid
    {
      appearance: 'critical',
      variant: 'solid',
      className: `
        bg-critical-600
        text-white

        hover:bg-critical-700
        focus:ring-critical-300
        active:bg-critical-900
      `,
    },
    {
      appearance: 'info',
      variant: 'solid',
      className: `
        bg-info-700
        text-white

        hover:bg-info-800
        focus:ring-info-300
        active:bg-info-900
      `,
    },
    {
      appearance: 'neutral',
      variant: 'solid',
      className: `
        bg-neutral-800

        text-white
        hover:bg-neutral-900
        focus:ring-neutral-300
        active:bg-black
      `,
    },
    {
      appearance: 'primary',
      variant: 'solid',
      className: `
        bg-primary-700
        text-white

        hover:bg-primary-800
        focus:ring-primary-300
        active:bg-primary-900
        `,
    },
    {
      appearance: 'secondary',
      variant: 'solid',
      className: `
        bg-secondary-600
        text-white

        hover:bg-secondary-700
        focus:ring-secondary-300
        active:bg-secondary-800
      `,
    },
    {
      appearance: 'success',
      variant: 'solid',
      className: `
        bg-primary-700
        text-white

        hover:bg-primary-800
        focus:ring-primary-300
        active:bg-primary-900
      `,
    },
    {
      appearance: 'tertiary',
      variant: 'solid',
      className: `
        bg-tertiary-600
        text-white

        hover:bg-tertiary-700
        focus:ring-tertiary-300
        active:bg-tertiary-800
      `,
    },
    {
      appearance: 'warning',
      variant: 'solid',
      className: `
        bg-warning-300
        text-neutral-900

        hover:bg-warning-500
        focus:ring-warning-200
        active:bg-warning-600
      `,
    },
    {
      social: 'apple',
      variant: 'solid',
      className: `
        bg-black
        text-white

        hover:bg-black/80
        focus:ring-black/30
        active:bg-black
      `,
    },
    {
      social: 'facebook',
      variant: 'solid',
      className: `
        bg-product-facebook
        text-white

        hover:bg-product-facebook/80
        focus:ring-product-facebook/30
        active:bg-product-facebook
      `,
    },
    {
      social: 'google',
      variant: 'solid',
      className: `
        bg-product-google
        text-white

        hover:bg-product-google/80
        focus:ring-product-google/30
        active:bg-product-google
      `,
    },
    {
      social: 'twitter',
      variant: 'solid',
      className: `
        bg-product-twitter
        text-white

        hover:bg-product-twitter/80
        focus:ring-product-twitter/30
        active:bg-product-twitter
      `,
    },
    //subtle
    {
      appearance: 'critical',
      variant: ['ghost', 'subtle'],
      className: `
        text-critical-700

        hover:bg-critical-100
        focus:ring-critical-300
        active:bg-critical-200
      `,
    },
    {
      appearance: 'info',
      variant: ['ghost', 'subtle'],
      className: `
        text-info-700

        hover:bg-info-100
        focus:ring-info-300
        active:bg-info-200
      `,
    },
    {
      appearance: 'neutral',
      variant: ['ghost', 'subtle'],
      className: `
        text-neutral-800

        hover:bg-neutral-100
        focus:ring-neutral-300
        active:bg-neutral-200
      `,
    },
    {
      appearance: 'primary',
      variant: ['ghost', 'subtle'],
      className: `
        text-primary-700

        hover:bg-primary-100
        focus:ring-primary-300
        active:bg-primary-200
      `,
    },
    {
      appearance: 'secondary',
      variant: ['ghost', 'subtle'],
      className: `
        text-secondary-600

        hover:bg-secondary-100
        focus:ring-secondary-300
        active:bg-secondary-200
      `,
    },
    {
      appearance: 'success',
      variant: ['ghost', 'subtle'],
      className: `
        text-primary-700

        hover:bg-primary-100
        focus:ring-primary-300
        active:bg-primary-200
      `,
    },
    {
      appearance: 'tertiary',
      variant: ['ghost', 'subtle'],
      className: `
        text-tertiary-600

        hover:bg-tertiary-100
        focus:ring-tertiary-300
        active:bg-tertiary-200
      `,
    },
    {
      appearance: 'warning',
      variant: ['ghost', 'subtle'],
      className: `
        text-warning-700

        hover:bg-warning-100
        hover:text-neutral-800
        focus:ring-warning-200
        active:bg-warning-300
        active:text-neutral-800
      `,
    },
    {
      social: 'apple',
      variant: ['ghost', 'subtle'],
      className: `
        text-black

        hover:bg-black/5
        focus:ring-black/30
        active:bg-black/20
      `,
    },
    {
      social: 'facebook',
      variant: ['ghost', 'subtle'],
      className: `
        text-product-facebook

        hover:bg-product-facebook/5
        focus:ring-product-facebook/30
        active:bg-product-facebook/25
      `,
    },
    {
      social: 'google',
      variant: ['ghost', 'subtle'],
      className: `
        text-product-google

        hover:bg-product-google/5
        focus:ring-product-google/30
        active:bg-product-google/25
      `,
    },
    {
      social: 'twitter',
      variant: ['ghost', 'subtle'],
      className: `
        text-product-twitter

        hover:bg-product-twitter/5
        focus:ring-product-twitter/30
        active:bg-product-twitter/25
      `,
    },
  ],
});

export default styles;
