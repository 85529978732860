import { tv } from 'tailwind-variants';

const styles = tv(
  {
    base: 'inline-flex',
    slots: {
      root: 'inline-flex',
    },
    variants: {
      appearance: {
        critical: {
          base: 'text-critical-600',
        },
        info: {
          base: 'text-info-600',
        },
        primary: {
          base: 'text-primary-600',
        },
        secondary: {
          base: 'text-secondary-600',
        },
        success: {
          base: 'text-success-600',
        },
        tertiary: {
          base: 'text-tertiary-600',
        },
        warning: {
          base: 'text-warning-600',
        },
      },
      color: {
        black: 'text-black',
        current: 'text-current',
        white: 'text-white',
      },
      size: {
        xs: {
          base: `
            h-3
            w-3
          `,
        },
        sm: {
          base: `
            h-4
            w-4
          `,
        },
        md: {
          base: `
            h-5
            w-5
          `,
        },
        lg: {
          base: `
            h-6
            w-6
          `,
        },
        xl: {
          base: `
            w-7
            h-7
          `,
        },
        '2xl': {
          base: `
            w-8
            h-8
          `,
        },
      },
    },
  },
  {
    responsiveVariants: true,
  }
);

export default styles;
