import React from 'react';
import type { SVGAttributes, ElementRef } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import styles from './Spinner.styles';

export interface SpinnerProps
  extends StyleProps<SVGAttributes<HTMLOrSVGElement>> {
  /**
   * The Spinner appearance
   */
  appearance?:
    | 'critical'
    | 'info'
    | 'primary'
    | 'success'
    | 'secondary'
    | 'tertiary'
    | 'warning';

  /**
   * The Spinner color
   *
   * @default current
   */
  color?: 'black' | 'current' | 'white';

  /**
   * The Spinner size
   *
   * @default lg
   */
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

const Spinner = React.forwardRef<ElementRef<'svg'>, SpinnerProps>(
  (props, ref) => {
    let {
      appearance,
      color = 'current',
      size = 'lg',
      UNSAFE_className,
      UNSAFE_style,
      ...rest
    } = props;

    return (
      <svg
        role="presentation"
        className={styles({
          appearance,
          color: appearance ? undefined : color,
          size,
          className: UNSAFE_className,
        })}
        style={UNSAFE_style}
        viewBox="0 0 50 50"
        ref={ref}
        {...rest}
      >
        <circle cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
      </svg>
    );
  }
);

Spinner.displayName = '@backyard-ui/core/Spinner';

export default Spinner;
